<template>
  <div class="honorequity-container">
    <div class="nav">
      <router-link to="/user/honor">荣誉认证</router-link>
    </div>
    <div class="table-container">
      <ul>
        <li v-for="(item, i) in honourList" :key="i">
          <span class="name">{{ item.honourName }}</span>
          <div style="width: 700px;">
            <span class="content">{{ item.honourDesc }}</span>
            <Poptip
              v-if="item.state === 2"
              trigger="hover"
              placement="right"
              word-wrap
            >
              <Icon class="iconBox" type="ios-help"></Icon>
              <div class="popContent" slot="content" v-text="'未通过-原因：'+item.content"></div>
            </Poptip>
          </div>
          <Button @click="authenticationFn(item)" v-if="item.state == undefined"
            >去认证</Button
          >
          <Button
            v-else-if="item.state === 0"
            style="border: 1px dotted #999999; color: #999999"
            >审核中</Button
          >
          <Button v-else-if="item.state === 1">已认证</Button>
          <Button
            @click="authenticationFn(item)"
            v-else-if="item.state === 2"
            style="border: 1px dashed #fd133f; color: #fd133f"
            >未通过</Button
          >
        </li>
      </ul>
    </div>
    <certificationModal :modalShow ="showModal" :hid="hid" :isHonor="name" @closeModal="closeModal"/>
  </div>
</template>

<script>
import certificationModal from "../../components/certificationModal.vue";
export default {
  components: {
    certificationModal
  },
  data() {
    return {
      showModal: false,
      honourList: [],
      name: "",
      hid: "",
    };
  },
  created() {
    this.getUserHonourList();
  },
  methods: {
    closeModal() {
      this.showModal = false;
      this.getUserHonourList();
    },
    authenticationFn(item) {
      this.name = item.honourName
      this.hid = item.id;
      this.showModal = true;
    },
    async getUserHonourList() {
      const resp = await this.$apis.userServe.getUserHonourList();
      this.honourList = resp.data;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-modal {
  p:nth-child(1) {
    margin: 14px 0;
  }
  p:nth-child(3) {
    margin: 35px 0px 10px 0;
  }
}
.picture {
  .demo-upload-list {
    display: inline-block;
    width: 134px;
    height: 134px;
    text-align: center;
    line-height: 134px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
}
.file-container {
  ::v-deep .ivu-upload-list-file {
    span {
        display: inline-block;
        max-width: 90%;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }
  }
  .demo-upload-list {
    height: 32px;
    line-height: 32px;
    vertical-align: middle;
    width: 55px;
    overflow: hidden;
    display: inline-block;
    margin: 0 7px;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
.honorequity-container {
  min-height: 800px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 40px;
  padding-top: 20px;
  .nav {
    height: 48px;
    border-bottom: 1px solid #e6e6e6;
    a {
      width: 125px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      &.router-link-exact-active::after {
        content: "";
        display: block;
        width: 125px;
        height: 8px;
        background-image: url("~@/assets/imgs/xz.png");
        background-size: 100% 100%;
      }
    }
  }
  .table-container {
    margin-top: 35px;
    height: 500px;
    padding: 0 32px 0 11px;
    ul {
      li {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 16px;
          color: #333333;
          width: 100px;
        }
        .content {
          font-size: 14px;
          color: #999999;
          width: 680px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;
        }
        .ivu-btn {
          width: 100px;
          height: 36px;
          background: #ffffff;
          border: 1px dashed #00a4ff;
          border-radius: 6px;
          font-size: 14px;
          color: #00a4ff;
        }
        ::v-deep .ivu-poptip-body {
          padding: 5px 10px;
          border-radius: 10px;
        }
        .iconBox {
          width: 16px;
          height: 16px;
          line-height: 16px;
          background: #FB1540;
          border-radius: 8px;
          color: #fff;
        }
        .popContent {
          max-width: 600px;
          word-break: break-all;
          color: red;
          font-size: 12px;
          text-align: justify;
        }
      }
    }
  }
}
</style>

